<template>
  <div>
    <AppSidebar />
    <div class="wrapper d-flex flex-column min-vh-100">
      <AppHeader />
      <div class="body flex-grow-1">
        <CContainer class="px-4 no-max-width">
          <div class="select-area">
            <CFormSelect v-model="selectedPath" @change="onSelectedTab()">
              <option v-for="tab in tabs" :key="tab.path" :value="tab.path">
                {{ $t(tab.name) }}
              </option>
            </CFormSelect>
          </div>
          <CTabs :active-item-key="route.path" class="tab-area">
            <CTabList variant="tabs">
              <CTab
                v-for="(tab, tabIndex) in tabs"
                :key="'tab-' + tabIndex"
                :class="{ active: tab.path === route.path }"
                :item-key="tab.path"
                @click="selectTab(tab)"
              >
                <CIcon :name="tab.icon" class="nav-icon" />&nbsp;
                {{ $t(tab.name) }} &nbsp;
                <CIcon
                  v-if="tab.closable"
                  name="cil-x"
                  class="nav-icon"
                  @click="closeTab($event, tab)"
                />
              </CTab>
            </CTabList>
          </CTabs>
          <div class="tab-contents">
            <div
              class="close-button-area float-end"
              @click="closeTab($event, selectedTab)"
            >
              <CIcon
                v-if="selectedTab && selectedTab.closable"
                name="cil-x"
                class="nav-icon"
              />
            </div>
            <div class="breadcrumb-area">
              <AppBreadcrumb class="clear" />
            </div>
            <div class="clear">
              <slot />
            </div>
          </div>
        </CContainer>
      </div>
      <AppFooter />
    </div>
  </div>
</template>
<script setup lang="ts">
import { CContainer } from "@coreui/vue/dist/esm/components/grid";
import { CTab, CTabList, CTabs } from "@coreui/vue/dist/esm/components/tabs";
// eslint-disable-next-line import/no-named-as-default
import CIcon from "@coreui/icons-vue";
import { CFormSelect } from "@coreui/vue/dist/esm/components/form";
import AppSidebar from "~/components/AppSidebar.vue";
// 메뉴의 경로는 이미 불려온 상태이므로, 현재 경로가 로컬 스토리지에 저장된 메뉴들 경로 중 있는지 확인을 한다.
let tabs: any;
const route = useRoute();
if (localStorage.openedTabs) {
  tabs = ref(JSON.parse(localStorage.openedTabs));
} else {
  tabs = ref([]);
}
const router = useRouter();
const selectedPath = ref("");
const selectedTab = ref();
// 없는 경우는 생성한다.
watch(
  () => route.fullPath,
  (newFullPath) => {
    selectedPath.value = route.path;
    if (route.path === "/login") {
      return;
    }
    let found = false;
    for (let i = 0; i < tabs.value.length; i++) {
      if (tabs.value[i].path === route.path) {
        found = true;
        tabs.value[i].fullPath = newFullPath;
        selectedTab.value = tabs.value[i];
      }
    }
    if (!found) {
      const routes = router.getRoutes();
      let foundRoute;
      let name;
      let icon;
      for (let i = 0; i < routes.length; i++) {
        if (routes[i].path === route.path) {
          name = routes[i].name;
          foundRoute = route;
          if (routes[i].meta) {
            icon = routes[i].meta.icon;
          }
          break;
        }
      }
      let closable = true;
      if (foundRoute && foundRoute.path === "/") {
        name = "page.name.dashboard";
        icon = "cil-chart";
        closable = false;
      } else if (foundRoute && foundRoute.meta && foundRoute.meta.nameAlias) {
        name = foundRoute.meta.nameAlias;
      }
      const newTab = {
        path: route.path,
        fullPath: route.fullPath,
        name,
        icon,
        closable,
      };
      selectedTab.value = newTab;
      tabs.value.push(newTab);
      let foundDashboard = false;
      for (let i = 0; i < tabs.value.length; i++) {
        if (tabs.value[i].path === "/") {
          foundDashboard = true;
        }
      }
      if (!foundDashboard) {
        tabs.value.unshift({
          path: "/",
          fullPath: "/",
          name: "page.name.dashboard",
          icon: "cil-chart",
          closable: false,
        });
      }
      localStorage.openedTabs = JSON.stringify(tabs.value);
    }
  },
  {
    immediate: true,
    deep: true,
  },
);

function selectTab(tab: object) {
  navigateTo(tab.fullPath);
  selectedTab.value = tab;
}

function onSelectedTab() {
  nextTick(() => {
    for (let i = 0; i < tabs.value.length; i++) {
      const tab = tabs.value[i];
      if (tab.path === selectedPath.value) {
        selectTab(tab);
        break;
      }
    }
  });
}

function closeTab(e: object, tab: object) {
  if (e) {
    e.preventDefault();
    e.stopPropagation();
  }
  const index = tabs.value.findIndex((paramTab) => tab.path === paramTab.path);
  if (index !== -1) {
    tabs.value.splice(index, 1);
  }
  localStorage.openedTabs = JSON.stringify(tabs.value);
  if (localStorage.visitedRoutes) {
    const routes = JSON.parse(localStorage.visitedRoutes);
    for (let i = routes.length - 1; i >= 0; i--) {
      if (routes[i].path === tab.path) {
        routes.splice(i, 1);
      }
    }
    localStorage.visitedRoutes = JSON.stringify(routes);
    if (routes.length) {
      navigateTo(routes[routes.length - 1].fullPath);
    } else {
      navigateTo("/");
    }
  }
}
// 메뉴는 MDI 형태로 열리며, MDI는 로컬 스토리지에 저장된다.
// 항상 현재 상태는 URL로 표현된다.
</script>

<style scoped>
.no-max-width {
  max-width: none !important;
}
.tab-contents {
  padding: 1rem;
}
.breadcrumb-area {
  margin-bottom: 1rem;
}
.select-area {
  display: none;
}
.tab-area {
  display: block;
}
.close-button-area {
  display: none;
  cursor: pointer;
  padding: 0.2rem 0 0.2rem 1rem;
}
@media (max-width: 575px) {
  .tab-area {
    display: none;
  }
  .select-area {
    display: block;
  }
  .close-button-area {
    display: block;
  }
}
</style>
